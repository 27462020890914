#homePage {
  --background: rgb(220, 220, 220);
}

#homeToolbar {
  --background: var(--ion-color-primary);
  --color: white;
  text-align: center;

  box-shadow: 0px 5px 5px #888888;

  padding-bottom: 0px;
  padding-top: 0px;
  height: 30px;
}

.mainTitle {
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 25px;
  padding-top: 0px;
}

.errorStyle {
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 25px;
  padding-top: 0px;
  color: red;
}

.foreGroundStyle {
  --background: var(--ion-color-primary);
  background-color: var(--ion-color-primary);
}
.greenStyle {
  --background: green;
}
.backGroundStyle {
  --background: rgb(220, 220, 220);
}

.trueButton {
  --background: green;
  margin-left: 10%;
}

.falseButton {
  --background: red;
  margin-right: 10%;
}

#flagsSlide n {
  margin-top: 0%;
}

#flagsSegment ion-segment-button {
  text-transform: none;
}

#searchBar {
  margin-left: 0px;
  padding-left: 0px;
}

#questionHeader {
  text-align: center;
}

.tabButtonStyle {
  --color: white;
  --color-selected: black;
}

#resultPanel {
  --height: 60%;
}

#badgeImg {
  height: 200px;
  width: 200px;
  border-radius: 50% !important;
}

#medal {
  text-align: center;
}

ion-card-subtitle {
  font-size: 20px;
  text-align: center;
}

#badge-card {
  text-align: center;
  color: white;
  background-color: grey;
  /* border: 1px solid var(--ion-color-primary); */
}
#badge-card ion-card-header ion-card-title {
  color: white;
}
.centerText {
  text-align: center;
  font-size: 20px;
  color: var(--ion-color-primary);
}

#resultIcon {
  position: absolute;
  top: 12%;
  right: 0%;
  font-size: 140px;
  width: 100%;
  font-weight: bold;
  color: darkorange;
}

#launchButton {
  margin-left: 15%;
  margin-right: 15%;
  text-transform: none;
}
#locationButton {
  vertical-align: middle;
  padding-top: 10px;
  padding-bottom: 0px;
}

/* @media (min-width : 700px){
    #homePage {
      --background: rgb(220, 220, 220);
      --padding-start:15%;
      --padding-end:15%;
    }

    #questionsSlides,#launchButton, #bottomBar {
      margin-left: 15%;
      margin-right: 15%;
    }
  }



  @media (min-width : 900px){
    #homePage {
      --background: rgb(220, 220, 220);
      --padding-start:20%;
      --padding-end:20%;
    }
    #questionsSlides,#launchButton, #bottomBar {
      margin-left: 20%;
      margin-right: 20%;
    }



  }

  @media (min-width : 1375px){
    #homePage {
      --background: rgb(220, 220, 220);
      --padding-start:25%;
      --padding-end:25%;
    }
    #questionsSlides,#launchButton, #bottomBar {
      margin-left: 25%;
      margin-right: 25%;     
    } 


  }*/
@media (pointer: fine) {
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-track:hover {
    background: #f7f7f7;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
  .inner-scroll {
    scrollbar-width: thin;
  }
}
